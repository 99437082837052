<script setup lang="ts">
import ui from '/~/core/ui'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import { useCheckoutReactive } from '/~/composables/checkout'
import PaymentsMakeSuccessful from './payments-make-successful.vue'

const { payment } = useCheckoutReactive()
</script>

<template>
  <div v-if="!ui.mobile" class="mx-auto max-w-2xl">
    <payments-make-successful />
  </div>
  <base-aside-page
    v-else
    :title="payment?.confirmation?.order?.statusLabel?.heading"
    no-padding
    shadow
    :back="false"
  >
    <div class="sm:pb-7">
      <payments-make-successful />
    </div>
  </base-aside-page>
</template>
