<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import emitter from '/~/core/emitter'
import BatchPayeeTable from '/~/extensions/bank-file-upload/components/transactions/payee-table/payee-table.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import PointsPanel from '/~/components/points/points-panel.v3.pure.vue'
import { useBatchOrderPayeesTable } from '/~/composables/batch-order'
import { useCheckoutReactive } from '/~/composables/checkout'
import {
  FlowType,
  getFlowTypeFromOrderNumber,
} from '/~/composables/checkout/checkout-types'
import {
  usePaymentMethods,
  sortPaymentMethodsByType,
  PaymentMethodType,
} from '/~/composables/payment-methods'
import { usePoints } from '/~/composables/points'
import { usePurchases } from '/~/composables/purchases'
import { useUser } from '/~/composables/user'
import PayidStatusDescription from '/~/templates/bill-payments-v1_5/views/payments/make/views/successful/components/payid-status-description.vue'
import PaymentSuccessfulV2Pure from './payments-make-successful.pure.vue'

const route = useRoute()
const router = useRouter()
const { user } = useUser()
const {
  payment,
  currentFlowType,
  isPurchaseGroupCurrentFlowType,
  isBatchOrderCurrentFlowType,
} = useCheckoutReactive()
const { purchases, fetchCurrentPurchases } = usePurchases()
const { calculatePointsEarnedForPayment, calculatePointsEarnedForPurchase } =
  usePoints()
const { initBatchOrderPayeesTable, batchOrderPayeesTable } =
  useBatchOrderPayeesTable()
const { hasPaymentMethodByType } = usePaymentMethods()

const loaded = ref(false)
const failed = ref(false)

const orderId = computed(() => route.params.orderId)

const confirmation = computed(() => payment.value.confirmation)
const order = computed(() => {
  const order: any = confirmation.value?.order

  if (!order) return

  return Object.setPrototypeOf(
    {
      ...order,
      paymentMethods: sortPaymentMethodsByType(
        (order.paymentMethods?.length > 0
          ? order.paymentMethods
          : order.scheduledPaymentMethods?.map((method) => {
              // temporary fix because of lack of information from the backend for scheduled payments
              method.finalAmount =
                Number(method.amount) + Number(order.fees?.total ?? 0)

              return method
            })) ?? []
      ),
    },
    Object.getPrototypeOf(order || {})
  )
})

const tier = computed(
  () => order.value.metadata?.membershipName || user.value.membershipName
)

const hasPayIdPaymentMethod = computed(() =>
  hasPaymentMethodByType(order.value.paymentMethods, PaymentMethodType.payId)
)

const orderStatus = computed(() => {
  const { status = '' } = order.value ?? {}

  return status.toLowerCase()
})

const financialStatus = computed(() => {
  const { financialStatus = '' } = order.value ?? {}

  return financialStatus.toLowerCase()
})

const isCompleted = computed(() => {
  if (isPurchaseGroupCurrentFlowType.value) {
    return (
      financialStatus.value === 'paid' &&
      ['processing', 'completed'].includes(orderStatus.value)
    )
  } else {
    return ['completed', 'payees_synced'].includes(orderStatus.value)
  }
})

const isRemittancePending = computed(
  () => orderStatus.value === 'remittance_pending'
)

const isPending = computed(() => {
  if (isPurchaseGroupCurrentFlowType.value) {
    return !isCompleted.value && orderStatus.value === 'processing'
  } else {
    return (
      ['processing', 'pending', 'awaiting_funds_pi'].includes(
        orderStatus.value
      ) || isRemittancePending.value
    )
  }
})

const shouldBeDeliveredToEwallet = computed(() => {
  if (!firstItem.value) {
    return false
  }

  return (
    ['giftcard', 'voucher'].includes(firstItem.value.type) &&
    firstItem.value.physical === false
  )
})
const isFailed = computed(() => {
  return (
    !isCompleted.value && !isPending.value && !order.value?.isStatusScheduled
  )
})
const items = computed(() => {
  return order.value?.items ?? []
})
const firstItem = computed(() => {
  return items.value[0]
})

const pointsEarned = computed(() => {
  const pointsTransactions = order.value?.pointsTransactions || []
  const earnTransaction = pointsTransactions.filter((transaction: any) => {
    return transaction.type === 'earn'
  })
  const allPointsCompleted = earnTransaction.every(
    (transaction: any) => transaction.status === 'completed'
  )

  if (earnTransaction.length > 0) {
    return {
      points: earnTransaction.reduce(
        (pointsEarned: number, transaction: any) => {
          return pointsEarned + transaction.allocatedValue
        },
        0
      ),
      status: allPointsCompleted ? 'completed' : 'pending',
    }
  }

  if (isPurchaseGroupCurrentFlowType.value && order.value?.items) {
    return {
      points: calculatePointsEarnedForPurchase(order.value.items),
      status: 'pending',
    }
  } else {
    return {
      points: calculatePointsEarnedForPayment(order.value?.subtotal ?? 0),
      status: 'pending',
    }
  }
})

const hasPurchaseItem = computed(() => {
  return (
    purchases.active.hits?.find(
      (purchase: any) => purchase.orderNumber === order.value?.number
    ) ||
    purchases.pending.hits?.find(
      (purchase: any) => purchase.orderNumber === order.value?.number
    )
  )
})

const showViewButton = computed(
  () =>
    !isBatchOrderCurrentFlowType.value &&
    (order.value?.isStatusScheduled ||
      hasPurchaseItem.value ||
      (!isPurchaseGroupCurrentFlowType.value &&
        Boolean(payment.value.rootRoute)))
)

const viewButtonTitle = computed(() => {
  if (order.value?.isStatusScheduled) {
    return 'View scheduled'
  }

  if (hasPurchaseItem.value) {
    return 'View eGift Cards'
  }

  return 'Make another payment'
})

async function syncDispatchedStatus() {
  await fetchCurrentPurchases()

  firstItem.value.dispatched = true
}

onBeforeMount(async () => {
  try {
    currentFlowType.value =
      getFlowTypeFromOrderNumber(orderId.value) ?? FlowType.purchase

    await payment.value.getOrder(orderId.value)

    if (isBatchOrderCurrentFlowType.value) {
      initBatchOrderPayeesTable()
    }

    if (shouldBeDeliveredToEwallet.value) {
      emitter.on('notifications:order_item_dispatched', syncDispatchedStatus)
    }
  } catch (error) {
    console.error(error)
    failed.value = true
  } finally {
    loaded.value = true
  }
})

onBeforeUnmount(() => {
  if (!isFailed.value) {
    payment.value.reset()
  }

  emitter.off('notifications:order_item_dispatched', syncDispatchedStatus)
})

function onView() {
  if (order.value?.isStatusScheduled) {
    router.push({
      name: 'scheduled-payments-list',
      params: {
        type: 'payments',
      },
    })
  } else if (hasPurchaseItem.value) {
    const purchaseRoute = {
      hash: '#profile-e-purchases',
    }

    router.push(purchaseRoute)
  } else if (payment.value.rootRoute) {
    // TODO: update checkout
    // router.push(payment.rootRoute)
    router.push({ name: 'payments-make' })
  } else {
    router.push({ name: 'home' })
  }
}

function onDone() {
  if (payment.value.doneRoute) {
    router.push(payment.value.doneRoute)
  } else {
    router.push({ name: 'home' })
  }
}
</script>

<template>
  <div class="flex w-full grow flex-col overflow-y-auto">
    <div class="mx-auto flex w-full grow flex-col sm:max-w-[672px]">
      <base-loader v-if="!loaded" fullwidth class="my-20" />

      <div
        v-else-if="failed || !order"
        class="py-[30px] text-center text-2xl font-bold"
      >
        Order information not found

        <div class="mt-[30px]">
          <base-button full-width @click="$router.push({ name: 'home' })">
            Back to Dashboard
          </base-button>
        </div>
      </div>

      <payment-successful-v2-pure
        v-else
        :flow-type="currentFlowType"
        :order="order"
        :payment="payment"
        :view-button-enabled="showViewButton"
        :view-button-title="viewButtonTitle"
        @view="onView"
        @done="onDone"
      >
        <template slot="points-panel">
          <points-panel
            v-if="!isFailed && payment.isOrderPoints"
            :points="pointsEarned.points"
            :status="pointsEarned.status"
            :level="tier"
            :border-x="false"
          />
        </template>

        <template slot="payid-status-description">
          <payid-status-description
            v-if="hasPayIdPaymentMethod"
            class="m-6"
            :status="order.status"
            :sub-status="order.subStatus"
            :activity="order"
          />
        </template>

        <template v-if="isBatchOrderCurrentFlowType" slot="batch-table">
          <batch-payee-table
            :pagination="batchOrderPayeesTable.pagination"
            :payees="batchOrderPayeesTable.transactionList.list"
            :tabs="batchOrderPayeesTable.tabs.list"
            :loading="batchOrderPayeesTable.loader.loading"
            class="py-6 text-eonx-neutral-800"
            @select-tab="batchOrderPayeesTable.setActiveTab($event)"
            @select-page="batchOrderPayeesTable.load($event)"
            @select-per-page="batchOrderPayeesTable.setPerPage(`${$event}`)"
          />
        </template>
      </payment-successful-v2-pure>
    </div>
  </div>
</template>
